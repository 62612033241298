import { useApolloClient } from "@apollo/client";
import { Form } from "formik";
import { isEmpty } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Link from "next/link";

import {
  Box,
  GridItem,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { EMAIL_REGEX, PasswordField, PASSWORD_REGEX } from "@/components/auth";
import {
  CheckboxInput,
  EmailInput,
  FormFooter,
  FormikQL,
  PhoneNumberInput,
  TextInput,
} from "@/components/form";
import {
  AcceptInvitationMutation,
  InvitationPageInvitationFragment,
  Session,
  useAcceptInvitationMutation,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { useToken } from "@/hooks/useToken";
import { reset as resetAuth, setAuthFromSession } from "@/state/auth";
import { constants, validPhoneNumber } from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required(`First name is required`),
  lastName: Yup.string().nullable().required(`Last name is required`),
  email: Yup.string()
    .nullable()
    .required(`Email is required`)
    .matches(EMAIL_REGEX, `Invalid email address`),
  phoneNumber: Yup.string()
    .nullable()
    .required(`Phone number is required`)
    .test(
      `valid phone number`,
      `Please enter a valid phone number`,
      validPhoneNumber,
    ),
  password: Yup.string()
    .nullable()
    .required(`Password is required`)
    .matches(
      PASSWORD_REGEX,
      `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
    ),
  passwordConfirmation: Yup.string()
    .nullable()
    .required(`Password confirmation is required`)
    .oneOf([Yup.ref(`password`)], `Passwords must match`),
  hasAgreedToPolicy: Yup.boolean().required(`Required`),
});

interface InvitationFormValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly hasAgreedToPolicy: boolean;
}

const initialValues = (email: string): InvitationFormValues => ({
  firstName: ``,
  lastName: ``,
  email,
  phoneNumber: ``,
  password: ``,
  passwordConfirmation: ``,
  hasAgreedToPolicy: false,
});

const mapVariables = (token: string) => ({
  hasAgreedToPolicy: _hasAgreedToPolicy,
  ...values
}: InvitationFormValues) => ({
  input: {
    ...values,
    token,
    phoneNumber:
      isEmpty(values.phoneNumber) || values.phoneNumber.length < 3
        ? null
        : normalizePhoneNumber(values.phoneNumber),
  },
});

const buildPayloadFromSession = (session: Session) => ({
  token: session.token,
  refreshToken: session.refreshToken,
});

const InvitationForm = ({
  invitation,
  token,
}: {
  readonly invitation: InvitationPageInvitationFragment;
  readonly token: string;
}) => {
  const { successToast } = useCustomToast();
  const mutation = useAcceptInvitationMutation();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const authToken = useToken();

  useEffect(() => {
    if (!authToken) return;

    dispatch(resetAuth());
    client.clearStore();
  }, []);

  const onSuccess = (data: AcceptInvitationMutation) => {
    if (!data.acceptInvitation?.session) return;

    successToast(`Account created successfully!`);

    const sessionPayload = buildPayloadFromSession(
      data.acceptInvitation.session,
    );

    dispatch(setAuthFromSession(sessionPayload));
  };

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`acceptInvitation`]}
      initialValues={initialValues(invitation.email)}
      validationSchema={validationSchema}
      mapVariables={mapVariables(token)}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <Form autoComplete="off">
          <SimpleGrid columns={2} columnGap={5} rowGap={6} w="full">
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <TextInput
                isRequired
                name="firstName"
                label="Name"
                placeholder="First"
                bg="h-white"
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <Spacer display={{ base: `none`, md: `block` }} h={6} />
              <TextInput name="lastName" placeholder="Last" bg="h-white" />
            </GridItem>
            <GridItem colSpan={2}>
              <PhoneNumberInput
                isRequired
                name="phoneNumber"
                label="Telephone"
              />
            </GridItem>
            <GridItem colSpan={2}>
              <EmailInput
                isDisabled
                name="email"
                type="email"
                label="Email"
                placeholder="Email address"
                bg="h-white"
              />
            </GridItem>
            <GridItem colSpan={2}>
              <VStack spacing={3}>
                <PasswordField
                  isRequired
                  name="password"
                  label="Password"
                  placeholder="Enter a password"
                  showPolicy
                />
                <PasswordField
                  name="passwordConfirmation"
                  placeholder="Confirm password"
                />
              </VStack>
            </GridItem>
          </SimpleGrid>
          <HStack spacing={4} mt={5}>
            <CheckboxInput
              name="hasAgreedToPolicy"
              dataTestId="termsCheckbox"
              label={
                <Text textStyle="deprecated-text-sm" cursor="pointer">
                  I agree to Hiive’s{` `}
                  <Link
                    href={`${constants.marketing_website_url}/terms`}
                    target="_blank"
                  >
                    <Box
                      as="span"
                      textStyle="deprecated-text-md"
                      cursor="pointer"
                    >
                      Terms of Use
                    </Box>
                  </Link>
                  {` `}
                  and{` `}
                  <Link
                    href={`${constants.marketing_website_url}/privacy`}
                    target="_blank"
                  >
                    <Box
                      as="span"
                      textStyle="deprecated-text-md"
                      cursor="pointer"
                    >
                      Privacy Policy.
                    </Box>
                  </Link>
                </Text>
              }
            />
          </HStack>
          <FormFooter
            disableSubmit={!values.hasAgreedToPolicy}
            isSubmitting={isSubmitting}
            submitText="Sign up"
            formName="InvitationForm"
          />
        </Form>
      )}
    </FormikQL>
  );
};

export default InvitationForm;
