import { useApolloClient } from "@apollo/client";
import { isEmpty } from "lodash/fp";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
  Box,
  Card,
  CardBody,
  Flex,
  GridItem,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { EMAIL_REGEX, PASSWORD_REGEX } from "@/components/auth";
import { SlideAnimation } from "@/components/auth-v2";
import { HiiveButton } from "@/components/common";
import {
  FormCheckboxInput,
  FormPasswordInput,
  FormPhoneNumberInput,
  FormTextInput,
} from "@/components/react-hook-form";
import {
  AcceptInvitationMutation,
  InvitationPageInvitationFragment,
  Session,
  useAcceptInvitationMutation,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { useToken } from "@/hooks/useToken";
import { reset as resetAuth, setAuthFromSession } from "@/state/auth";
import { constants, validPhoneNumber } from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string()
    .required(`Email is required`)
    .matches(EMAIL_REGEX, `Invalid email address`),
  phoneNumber: Yup.string()
    .required(`Phone number is required`)
    .test(
      `valid phone number`,
      `Please enter a valid phone number`,
      validPhoneNumber,
    ),
  password: Yup.string()
    .required(`Password is required`)
    .matches(
      PASSWORD_REGEX,
      `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
    ),
  passwordConfirmation: Yup.string()
    .required(`Password confirmation is required`)
    .oneOf([Yup.ref(`password`)], `Passwords must match`),
  hasAgreedToPolicy: Yup.boolean().required(`Required`),
});

interface InvitationFormValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly hasAgreedToPolicy: boolean;
}

const initialValues = (email: string): InvitationFormValues => ({
  firstName: ``,
  lastName: ``,
  email,
  phoneNumber: ``,
  password: ``,
  passwordConfirmation: ``,
  hasAgreedToPolicy: false,
});

const mapVariables = (token: string) => ({
  hasAgreedToPolicy: _hasAgreedToPolicy,
  ...values
}: InvitationFormValues) => ({
  input: {
    ...values,
    token,
    phoneNumber:
      isEmpty(values.phoneNumber) || values.phoneNumber.length < 3
        ? null
        : normalizePhoneNumber(values.phoneNumber),
  },
});

const buildPayloadFromSession = (session: Session) => ({
  token: session.token,
  refreshToken: session.refreshToken,
});

const InvitationForm = ({
  invitation,
  token,
}: {
  readonly invitation: InvitationPageInvitationFragment;
  readonly token: string;
}) => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const mutation = useAcceptInvitationMutation();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const authToken = useToken();

  useEffect(() => {
    if (!authToken) return;

    dispatch(resetAuth());
    client.clearStore();
  }, []);

  const onSuccess = (data: AcceptInvitationMutation) => {
    if (!data.acceptInvitation?.session) return;

    successToast(`Account created successfully!`);

    const sessionPayload = buildPayloadFromSession(
      data.acceptInvitation.session,
    );

    dispatch(setAuthFromSession(sessionPayload));
  };

  const { handleSubmit, isLoading, control, watch } = useFormQL({
    mutation,
    mapVariables: mapVariables(token),
    initialValues: initialValues(invitation.email),
    validationSchema,
    onSuccess,
  });

  const hasAgreedToPolicy = watch(`hasAgreedToPolicy`);

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={8}>
        <SlideAnimation>
          <Card>
            <CardBody>
              <SimpleGrid
                columns={2}
                columnGap={5}
                rowGap={{ base: 5, md: 6 }}
                w="full"
              >
                <GridItem colSpan={2} columnGap={5}>
                  <Flex
                    direction={{ base: `column`, md: `row` }}
                    rowGap={4}
                    columnGap={5}
                  >
                    <FormTextInput
                      control={control}
                      isRequired
                      name="firstName"
                      label={t(`name`)}
                      placeholder={t(`first`)}
                      autoFocus
                    />
                    <Box w="full">
                      <Spacer display={{ base: `none`, md: `block` }} h={6} />
                      <FormTextInput
                        mt={{ base: 0, md: 1.5 }}
                        control={control}
                        name="lastName"
                        placeholder={t(`last`)}
                        bg="white"
                        label={t(`last_name`)}
                        labelSrOnly
                      />
                    </Box>
                  </Flex>
                </GridItem>

                <GridItem colSpan={2}>
                  <FormPhoneNumberInput
                    isRequired
                    name="phoneNumber"
                    label={t(`telephone`)}
                    control={control}
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <FormTextInput
                    type="email"
                    isRequired
                    name="email"
                    label={t(`email`)}
                    placeholder={t(`email_address`)}
                    bg="white"
                    control={control}
                    isDisabled
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <VStack spacing={1}>
                    <FormPasswordInput
                      isRequired
                      name="password"
                      label={t(`password`)}
                      placeholder={t(`signup_password_placeholder`)}
                      control={control}
                    />
                    <Text textStyle="text-sm">
                      {t(`signup_password_policy`)}
                    </Text>
                  </VStack>
                </GridItem>

                <GridItem colSpan={2}>
                  <FormPasswordInput
                    control={control}
                    label={t(`signup_confirm_password`)}
                    labelSrOnly
                    name="passwordConfirmation"
                    placeholder={t(`signup_confirm_password`)}
                    isRequired
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormCheckboxInput
                    control={control}
                    name="hasAgreedToPolicy"
                    dataTestId="termsCheckbox"
                    label={
                      <Text textStyle="text-sm">
                        <Trans
                          i18nKey="signup_terms_and_policy_agreement"
                          components={[
                            <Link
                              key="terms"
                              variant="chunky"
                              target="_blank"
                              href={`${constants.marketing_website_url}/terms`}
                            />,
                            <Link
                              key="privacy"
                              variant="chunky"
                              target="_blank"
                              href={`${constants.marketing_website_url}/privacy`}
                            />,
                          ]}
                        />
                      </Text>
                    }
                  />
                </GridItem>
              </SimpleGrid>
            </CardBody>
          </Card>
        </SlideAnimation>

        <Flex justifyContent="flex-end" w="full">
          <HiiveButton
            type="submit"
            isLoading={isLoading}
            size="xl"
            w={{ base: `full`, sm: `unset` }}
            maxW="unset"
            variant="rounded-solid-salmon"
            isDisabled={!hasAgreedToPolicy}
            sentryLabel="[InvitationPage] Submit"
          >
            {t(`sign_up`)}
          </HiiveButton>
        </Flex>
      </VStack>
    </form>
  );
};

export default InvitationForm;
